import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';
import { Box, Button, TextField, Typography, Link, Alert, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid'; 
import SecurityIcon from '@mui/icons-material/Security';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShieldIcon from '@mui/icons-material/Shield';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false); // 是否记住密码
  const [error, setError] = useState('');
  const [showAnnouncement, setShowAnnouncement] = useState(false); 
  const [showUpdateLog, setShowUpdateLog] = useState(false); 
  const [version, setVersion] = useState('');
  const [updateLog, setUpdateLog] = useState('');
  const navigate = useNavigate();

  // 页面加载时检查存储的用户名和密码
  useEffect(() => {
    const savedUsername = document.cookie.split('; ').find(row => row.startsWith('username='))?.split('=')[1];
    const savedPassword = document.cookie.split('; ').find(row => row.startsWith('password='))?.split('=')[1];
    const savedRememberMe = document.cookie.split('; ').find(row => row.startsWith('rememberMe='))?.split('=')[1] === 'true';

    if (savedRememberMe) {
      setUsername(decodeURIComponent(savedUsername || ''));
      setPassword(decodeURIComponent(savedPassword || ''));
      setRememberMe(true);
    }
  }, []);

  // 获取系统版本号和更新日志
  useEffect(() => {
    const fetchSystemInfo = async () => {
      try {
        const response = await API.get('/api/update/system-info');
        setVersion(response.data.version);
        setUpdateLog(response.data.updateLog);
      } catch (error) {
        console.error('获取系统信息失败:', error);
      }
    };

    fetchSystemInfo();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await API.post(
        '/api/auth/login',
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const { token, role } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);

      // 根据勾选状态存储用户名和密码到 Cookie
      if (rememberMe) {
        document.cookie = `username=${encodeURIComponent(username)}; path=/; max-age=${60 * 60 * 24 * 30}; Secure; SameSite=Strict`;
        document.cookie = `password=${encodeURIComponent(password)}; path=/; max-age=${60 * 60 * 24 * 30}; Secure; SameSite=Strict`;
        document.cookie = `rememberMe=true; path=/; max-age=${60 * 60 * 24 * 30}; Secure; SameSite=Strict`;
      } else {
        document.cookie = 'username=; path=/; max-age=0; Secure; SameSite=Strict';
        document.cookie = 'password=; path=/; max-age=0; Secure; SameSite=Strict';
        document.cookie = 'rememberMe=; path=/; max-age=0; Secure; SameSite=Strict';
      }

      if (role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/user');
      }
    } catch (err) {
      console.error('登录失败:', err);
      setError('登录失败，请检查用户名和密码');
    }
  };

  const handleCloseAnnouncement = () => setShowAnnouncement(false); 
  const handleCloseUpdateLog = () => setShowUpdateLog(false); 
  const handleShowUpdateLog = () => setShowUpdateLog(true); 

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
      bgcolor="#f7f9fc"
      sx={{ position: 'relative' }}
    >
      <Grid container spacing={2} justifyContent="center" sx={{ width: '90%' }}>
        <Grid item xs={12} md={6}>
          <Box p={2}>
            <Box display="flex" justifyContent="center" mb={3} sx={{ marginTop: '-50px' }}> {/* 调整 Logo 的上外边距 */}
              <img
                src="https://tc-oss-2.1yidc.com/2024/09/25/66f40744318da.png"
                alt="Logo"
                style={{ width: '300px', height: '100px' }} // 调整图片大小
              />
            </Box>

            <Box>
              <Typography variant="h6" display="flex" alignItems="center" gutterBottom>
                <SecurityIcon sx={{ mr: 1 }} /> 应对DDoS攻击的重要性
              </Typography>
              <Typography variant="body1" paragraph>
                DDoS攻击会影响您的网络性能，降低服务器的稳定性。我们的系统旨在帮助用户实时监控和管理DDoS流量，确保服务器正常运行。
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" display="flex" alignItems="center" gutterBottom>
                <QueryStatsIcon sx={{ mr: 1 }} /> 如何进行流量查询？
              </Typography>
              <Typography variant="body1" paragraph>
                您可以通过登录DDoS流量监控系统，查看所有已分配IP的流量数据，并在发现异常流量时，采取相应的防护措施。
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" display="flex" alignItems="center" gutterBottom>
                <ShieldIcon sx={{ mr: 1 }} /> 常见的DDoS防护措施
              </Typography>
              <Typography variant="body1" paragraph>
                通过封掉UDP、设置限流等策略，您可以有效防护DDoS攻击，并使用我们系统提供的应用规则来进行针对性防护。
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              padding: 4,
              boxShadow: 3,
              borderRadius: 2,
              backgroundColor: 'white',
              width: '100%',
              maxWidth: '400px', 
              mb: 8 // 确保在手机上不会被版权信息遮盖
            }}
          >
            <Typography variant="h5" align="center" gutterBottom>
              SetonSentinel 安全平台登录
            </Typography>
            <form onSubmit={handleLogin}>
              <TextField
                label="用户名"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <TextField
                label="密码"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                }
                label="记住用户名和密码"
              />
              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                登录
              </Button>
            </form>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              没有账号？ <Link href="#" onClick={() => setShowAnnouncement(true)}>注册</Link>
            </Typography>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              忘记密码？ <Link href="/forgot-password">找回密码</Link>
            </Typography>

            <Typography
              variant="body2"
              align="center"
              sx={{ mt: 1, cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleShowUpdateLog}
            >
              系统版本号: {version} (点击查看更新内容)
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Dialog open={showAnnouncement} onClose={handleCloseAnnouncement} fullWidth maxWidth="sm">
        <DialogTitle>重要公告</DialogTitle>
        <DialogContent>
          <Typography align="center">
            本系统仅限已购买云服务器(十堰)以及托管(十堰)的用户使用，请确保已符合申请条件后前往下方的地址申请，不符合条件的申请将被驳回，请悉知。
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              href="/register"
              style={{ textTransform: 'none' }} // 取消默认大写
            >
              立即前往申请
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnouncement} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showUpdateLog} onClose={handleCloseUpdateLog} fullWidth maxWidth="sm">
        <DialogTitle>更新日志 - {version}</DialogTitle>
        <DialogContent
          style={{ 
            maxHeight: '400px',  // 设置最大高度
            overflowY: 'auto'    // 内容超出时自动显示滚动条
          }}
        >
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem' }}>{updateLog}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateLog} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ position: 'fixed', bottom: 0, width: '100%', bgcolor: '#f7f9fc', py: 2 }}>
        <Typography variant="body2" align="center">
          SetonSentinel 安全平台由 <Link href="https://www.11dun.com" target="_blank" rel="noopener">壹盾安全</Link> 提供技术支持
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;